export class FormValue {
	key: string;
	text: string;
	type: string;
	value: any;

	constructor(key: string, text: string, type: string, value: any) {
		this.key = key;
		this.text = text;
		this.type = type;
		this.value = value;
	}
}
