import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FormItem } from "../../../domain/FormItem";
import { replaceIdDelimiter } from "../../../utility/stringUtils";

type SelectInputProps = {
	item: FormItem;
	onChangeHandler(fieldId: string, text: string, type: string, value: any): any;
	enabled: boolean;
	value?: string | undefined;
};
const HeightInput = (props: SelectInputProps) => {
	const {
		item: { linkId, text, type, required }
	} = props;

	const [feet, setFeet] = useState("");
	const [inches, setInches] = useState("");

	useEffect(() => {
		if (props.value) {
			setFeet(props.value.split(" ")[0]);
			setInches(props.value.split(" ")[2]);
		}
	}, [props.value]);

	useEffect(() => {
		if (feet === "") {
			props.onChangeHandler(linkId, text, type, "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feet, inches]);

	const handleHeightInput = (feet: string, inches: string) => {
		props.onChangeHandler(linkId, text, type, `${feet} feet, ${inches !== "" ? inches : 0} inches`);
	};

	return (
		<div className={props.enabled ? "" : "hidden"}>
			<span>{text}</span>
			<TextField
				sx={{ mt: 4, mb: 2, width: 1 }}
				required={required}
				id={replaceIdDelimiter(linkId)}
				label="Feet"
				value={feet}
				onChange={(event) => {
					setFeet(event.target.value);
					handleHeightInput(event.target.value, inches);
				}}
				inputProps={{ "data-testid": replaceIdDelimiter(linkId), "name": replaceIdDelimiter(linkId) }}
				autoComplete="false"
				data-testid={"text-input-parent-" + replaceIdDelimiter(linkId)}
			/>
			<TextField
				sx={{ mt: 0, mb: 4, width: 1 }}
				required={required}
				id={replaceIdDelimiter(linkId)}
				label="Inches"
				value={inches}
				onChange={(event) => {
					setInches(event.target.value);
					handleHeightInput(feet, event.target.value);
				}}
				inputProps={{ "data-testid": replaceIdDelimiter(linkId), "name": replaceIdDelimiter(linkId) }}
				autoComplete="false"
				data-testid={"text-input-parent-" + replaceIdDelimiter(linkId)}
			/>
		</div>
	);
};

export default HeightInput;
