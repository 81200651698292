const STYLING_CONSTANTS = {
	ITEM_HEIGHT: 48,
	ITEM_PADDING_TOP: 8
};

const BOX_STYLING = {
	p: 2,
	bgcolor: "background.default",
	display: "flex",
	justifyContent: "center",
	gap: 2,
	my: 0,
	mx: "auto",
	maxWidth: 1
};

const PAPER_STYLING = {
	width: 1,
	p: 4,
	display: "flex",
	mt: 1,
	flexDirection: "column",
	boxSizing: "border-box"
};

const CENTER_FLEXBOX_STYLING = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
};

const SelectMenuProps = {
	PaperProps: {
		style: {
			maxHeight: STYLING_CONSTANTS.ITEM_HEIGHT * 4.5 + STYLING_CONSTANTS.ITEM_PADDING_TOP,
			width: 3 / 4
		}
	}
};

const DIVIDER_STYLING = {
	mt: 3,
	mb: 3
};

const LEFT_FLEXBOX_STYLING = {
	display: "flex",
	alignItems: "flex-start",
	justifyContent: "left",
	mt: 2
};
const LEFT_CENTER_FLEXBOX_STYLING = {
	display: "flex",
	alignItems: "center",
	justifyContent: "left"
};
const SUMMARY_BUTTON = {
	color: "white",
	bgcolor: "black",
	width: "100%",
	p: 1
};

export {
	SUMMARY_BUTTON,
	STYLING_CONSTANTS,
	BOX_STYLING,
	PAPER_STYLING,
	SelectMenuProps,
	DIVIDER_STYLING,
	CENTER_FLEXBOX_STYLING,
	LEFT_FLEXBOX_STYLING,
	LEFT_CENTER_FLEXBOX_STYLING
};
