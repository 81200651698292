import { EventType } from "../../utility/constants";

export class EventData {
	consultId: string | undefined;
	intakeStep: string;

	constructor(consultId: string = "", intakeStep: string) {
		this.consultId = consultId;
		this.intakeStep = intakeStep;
	}
}

export class UpdateIntakeProgressRequest {
	eventType: EventType;
	eventTime: string;
	eventData: EventData;

	constructor(eventType: EventType = EventType.STEP_CHANGE, eventTime: string, eventData: EventData) {
		this.eventType = eventType;
		this.eventTime = eventTime;
		this.eventData = eventData;
	}
}
