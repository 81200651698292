import { Stepper, Step, StepLabel, Box, Orientation } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Pharmacy } from "../../domain/Pharmacy";
import { LocalPharmacyOutlined } from "@mui/icons-material";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

import "./SuccessPage.css";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { SuccessPageAddress } from "../../domain/types/SuccessPageAddres";

type SuccessPageState = {
	pharmacy: Pharmacy;
	address: SuccessPageAddress;
};

const mapIconStyling = {
	p: 1,
	borderRadius: 100,
	backgroundColor: "#F6F6F7",
	color: "black",
	marginRight: 3
};

const SuccessPage = () => {
	const { state } = useLocation();
	const [queryParams] = useSearchParams();
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const [pharm, setPharmacy] = useState<Pharmacy>();
	const [orientation, setOrientation] = useState<Orientation | undefined>("horizontal");
	const [altLabel, setAltLabel] = useState<boolean>(false);
	const { pharmacy, address } = state as SuccessPageState;

	const isBrowser = typeof window !== "undefined";
	const steps = ["Diagnostics", "Telehealth consult", "Clinician review", "Prescription"];
	const modeParam = queryParams.get("mode");

	const handleWindowSizeChange = () => {
		if (isBrowser) {
			if (window.innerWidth <= 500) {
				setOrientation("vertical");
				setAltLabel(false);
			} else {
				setOrientation("horizontal");
				setAltLabel(true);
			}
		}
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("success_page");
		handleWindowSizeChange();
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pharmacy) {
			setPharmacy(pharmacy);
		}
	}, [pharmacy]);

	return (
		<PageWrapper enabled={true} id="success-page">
			<h2>Your Information Has Been Submitted</h2>
			<p>
				One of our licensed clinicians will review the submitted information and suggest a treatment plan that
				is best for you. You will receive an email shortly with next steps.
			</p>

			<Box className="center mt-2">
				<Stepper
					activeStep={2}
					alternativeLabel={altLabel}
					orientation={orientation}
					className="success-steper"
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel className="text-dark">{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>

			<div className="container">
				<span className="pharmacy-title">
					{address && modeParam && modeParam === "demo" ? "Shipping information" : "Preferred Pharmacy"}
				</span>
				<span className="font-md">Your prescriptions will be sent here.</span>

				<div className="h-flex-container mt-2">
					{address && modeParam && modeParam === "demo" ? (
						<div>
							<h3>{address.addressLine1}</h3>
							<h3>{address.addressLine2}</h3>
							<h3>
								{address.city}, {address.state} {address.postalCode}
							</h3>
						</div>
					) : (
						<>
							<LocalPharmacyOutlined sx={mapIconStyling} />
							<div className="address">
								<h4>{pharm?.pharmacyName}</h4>
								<div className="full-address">{Pharmacy.getFullAddress(pharmacy)}</div>
							</div>
						</>
					)}
				</div>
			</div>
		</PageWrapper>
	);
};

export default SuccessPage;
