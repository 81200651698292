const TextDisplay = (props: any) => {
	const {
		item: { linkId, text }
	} = props;

	return (
		<div className={props.enabled ? "" : "hidden"}>
			<h2 id={linkId}>{text}</h2>
		</div>
	);
};

export default TextDisplay;
