import { parseURL } from "../utility/stringUtils";
import { questionnaireUrl } from "./../utility/questionnaireEndpoint";

export class QuestionnaireService {
	static getQuestionnaireUrl = (consultId: string | undefined, procedure: keyof typeof questionnaireUrl) => {
		let url;
		if (consultId) {
			if (procedure) {
				url = questionnaireUrl[procedure] ? questionnaireUrl[procedure] : questionnaireUrl["covid"];
			}
			return parseURL(url ? url : process.env.REACT_APP_GET_COVID_QUESTIONNAIRE_URL, { consultId });
		} else return null;
	};
	static getFinalizeQuestionnaireUrl = () => process.env.REACT_APP_FINALIZE_QUESTIONNAIRE_URL;
}
