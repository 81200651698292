import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CONSTANTS, EventType, INTAKE_STEP } from "../../utility/constants";
import { FormValue } from "../../domain/FormValue";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { updateProgress } from "../../utility/metricsUtils";
import { constructQueryParams } from "../../utility/stringUtils";

import useHttp from "../../hooks/use-http/use-http";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

import "./ConsentPage.css";

export type ConsentStatus = {
	consent?: boolean;
	formValues?: FormValue[];
};

const ConsentPage = () => {
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const { state } = useLocation();
	const { consultId, zipCode } = useParams();

	const navigate = useNavigate();
	const { sendRequest } = useHttp();

	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);
	const [consent, setConsent] = useState<boolean | undefined>(false);
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [procedure, setProcedure] = useState<string>("");
	const [queryParams] = useSearchParams();
	const [mode, setMode] = useState("");

	const consentPDF = "../../../documents/consent-agreement.pdf";

	const consentText = (
		<label>
			I agree to the{" "}
			<a href={consentPDF} target="_blank" rel="noreferrer">
				Subscription Service Terms and Conditions of Use
			</a>
			*
		</label>
	);

	useEffect(() => {
		// Dependency on state is necessary since it'll contain all formValues when the user presses back and exits
		// the FormViewerPage.
		if (state) {
			const { consent, formValues } = state as ConsentStatus;
			setConsent(consent);
			setFormValues(formValues);
		}
		const redirectUri = queryParams.get("redirect_uri");
		const modeParam = queryParams.get("mode");
		const procedureParam = queryParams.get("procedure");

		if (redirectUri) {
			setRedirectUri(`redirect_uri=${redirectUri}`);
		}
		if (procedureParam) {
			setProcedure(`procedure=${procedureParam}`);
		}
		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [state, queryParams, setExitPoint]);

	const navigationHandler = () => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		navigate(`/${zipCode}/consult/${consultId}/start/questionnaire${queryParams}`, {
			state: {
				consent: consent,
				formValues: formValues
			}
		});
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("consent_page");
		updateProgress(EventType.STEP_CHANGE, consultId, INTAKE_STEP.CONSENT_PAGE, sendRequest);
	}, [consultId, sendRequest, setExitPoint]);

	return (
		<PageWrapper enabled={true} id="consent-page-wrapper">
			<h2>Your Privacy Is Important To Us!</h2>

			<FormControlLabel
				sx={{ my: 1, width: 1 }}
				id="consent"
				control={
					<Checkbox
						id="consent-box"
						required={true}
						checked={consent || false}
						onChange={(event) => {
							setConsent(event.target.checked);
						}}
						inputProps={{
							// @ts-ignore
							"data-testid": "consent-box"
						}}
					/>
				}
				label={consentText}
			/>

			<p className="disclaimer-text">{CONSTANTS.CONSENT_DISCLAIMER}</p>

			<div className="form-nav-button-container mt-1">
				<div className="form-nav-button-container flex-end mt-1">
					<Button
						id="continue"
						disabled={!consent}
						variant="outlined"
						className="form-nav-button link-button right"
						onClick={navigationHandler}
					>
						Continue
						<ChevronRight fontSize="inherit" />
					</Button>
				</div>
			</div>
		</PageWrapper>
	);
};

export default ConsentPage;
