import FormElement, { TFormAnswerOption } from "../../../domain/types/FormElement";
import { getLabelId, trimAllWhiteSpace } from "../../../utility/stringUtils";
import { Button } from "@mui/material";
import { FormItem } from "../../../domain/FormItem";
import { useEffect, useState } from "react";
import { trimAndLowerCaseString } from "../../../utility/stringUtils";

type SelectInputProps = {
	item: FormItem;
	onChangeHandler(fieldId: string, text: string, type: string, value: any): any;
	enabled: boolean;
	value?: string[] | undefined;
};

//disableKeyWords will disable avery other value to be selected
const disableKeywords = ["none", "none of the above", "none apply to me"];

//selectAllKeywords will prove that in the UI the user will see all the options selected but the value will still be "Select all"
const selectAllKeyword = "selectall";

const SelectInput = (props: SelectInputProps) => {
	const [selectedValues, setSelectedValues] = useState<string[]>([]);
	const [disabledOptions, setDisabledOptions] = useState<boolean>(false);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const formElement: FormElement = props.item;
	const {
		item: { linkId, text, type, repeats }
	} = props;
	const labelId: string = getLabelId(formElement.linkId);

	const onChangeHandler = (value: string) => {
		//repeats conditionates de behavior of component
		if (repeats) {
			multiSelectHandler(value);
		} else {
			radioBtnTypeSelect(value);
		}
	};

	// if component behaves like a radio input (property repeats: false)
	const radioBtnTypeSelect = (value: string) => {
		props.onChangeHandler(linkId, text, type, [value]);
		setSelectedValues([value]);
	};

	const disableOptions = () => {
		if (props.value) {
			props.value.forEach((value) => {
				if (disableKeywords.some((keyWord) => value.toLowerCase() === keyWord)) {
					setDisabledOptions(true);
				} else {
					setDisabledOptions(false);
				}
			});
		}
	};

	const selectAllOptions = (values: string[]): boolean => {
		//Checking it the "Selected All" option is included in the answers array, covering capitalized words and unintentionall white spaces.
		return values.some((answer: string) => trimAndLowerCaseString(answer) === selectAllKeyword);
	};

	//if component behaves like a multiselect / checkbox (property repeats: true)
	const multiSelectHandler = (value: string) => {
		let newValues: string[] = [];
		if (props.value && props.value.length > 0) {
			newValues = [...props.value];
		}

		let index = newValues.findIndex((val: string) => val === value);
		if (index !== -1) {
			newValues.splice(index, 1);
			setSelectAll(false);
		} else {
			//If the "select all" option is selected, we won't be able to push other values. They will display as selected, but we can't push individual values.
			if (!selectAllOptions(newValues)) {
				newValues.push(value);
			}

			if (trimAndLowerCaseString(value) === selectAllKeyword) {
				//Here we make sure that is we chose the "Select all" option, that would be the value colected to send to the API.
				setSelectAll(true);
				newValues = [value];
			}
		}
		setSelectedValues(newValues);

		if (
			disableKeywords.some((word: string) => word === value.toLowerCase()) &&
			newValues.findIndex((val: string) => val === value) !== -1
		) {
			setSelectedValues([value]);
			newValues = [value];
			setDisabledOptions(true);
		} else {
			setDisabledOptions(false);
		}

		props.onChangeHandler(linkId, text, type, newValues);
	};

	useEffect(() => {
		if (props.value !== null && props.value !== undefined) {
			setSelectedValues(props.value);
			disableOptions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	const checkMark = (selected: boolean) => {
		return (
			<div className={selected ? "radio-checks checkbox-btn selected" : "checkbox-btn"}>
				<div />
			</div>
		);
	};
	const radioMark = (selected: boolean) => {
		return (
			<div className={selected ? "radio-checks radio-btn selected" : "radio-checks radio-btn"}>
				<div />
			</div>
		);
	};
	return (
		<div className={props.enabled ? "" : "hidden"}>
			<h2 id={labelId}>{formElement.text}</h2>
			<div className="vertical-button-container mb-2">
				{formElement.answerOption?.map((answerOption: TFormAnswerOption, index: number) => {
					const parsedId = trimAllWhiteSpace(answerOption.valueString);
					return (
						<Button
							key={`option-${parsedId}`}
							disabled={
								!disableKeywords.some((word) => word === answerOption.valueString.toLowerCase()) &&
								disabledOptions
							}
							data-testid={`option-${index}-${parsedId}`}
							id={`option-${parsedId}`}
							variant="outlined"
							className={
								"select-button " +
								(selectedValues?.includes(answerOption.valueString) || selectAll ? "button-active" : "")
							}
							onClick={() => {
								onChangeHandler(answerOption.valueString);
							}}
						>
							<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
								<div className="select-label">{answerOption.valueString}</div>
								{repeats || selectedValues.includes("Select all")
									? checkMark(
											selectedValues?.includes(answerOption.valueString) ||
												selectAllOptions(selectedValues)
									  )
									: radioMark(selectedValues?.includes(answerOption.valueString))}
							</div>
						</Button>
					);
				})}
			</div>
		</div>
	);
};

export default SelectInput;
