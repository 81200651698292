import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { FormItem } from "../../../domain/FormItem";
import { replaceIdDelimiter } from "../../../utility/stringUtils";

type TextInputProps = {
	item: FormItem;
	onChangeHandler(fieldId: string, text: string, type: string, value: any): any;
	enabled: boolean;
	value?: string | undefined;
};

const TextInput = (props: TextInputProps) => {
	const [value, setValue] = useState("");
	const {
		item: { linkId, text, type, required }
	} = props;

	useEffect(() => {
		if (props.value !== null && props.value !== undefined) {
			setValue(props.value);
		}
	}, [props.value]);

	return (
		<div className={props.enabled ? "" : "hidden"}>
			<span>{text}</span>
			<TextField
				sx={{ mt: 4, mb: 4, width: 1 }}
				required={required}
				id={replaceIdDelimiter(linkId)}
				label={text}
				value={value}
				onChange={(event) => {
					setValue(event.target.value);
					props.onChangeHandler(linkId, text, type, event.target.value);
				}}
				inputProps={{ "data-testid": replaceIdDelimiter(linkId), "name": replaceIdDelimiter(linkId) }}
				autoComplete="false"
				data-testid={"text-input-parent-" + replaceIdDelimiter(linkId)}
			/>
		</div>
	);
};

export default TextInput;
