import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PATH_PARAMS } from "./utility/constants";
import { ExitPointProvider } from "./context/exit-point";

import FormViewerPage from "./pages/FormViewerPage/FormViewerPage";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import DenialPage from "./pages/DenialPage/DenialPage";
import PharmacyPickerPage from "./pages/PharmacyPickerPage/PharmacyPickerPage";
import ConsultationSummary from "./pages/SummaryPage/SummaryPage";
import "./index.css";
import ShippingAddressPage from "./pages/ShippingAddressPage/shipping-address-page";
import ConsentPage from "./pages/ConsentPage/ConsentPage";

ReactDOM.render(
	<ExitPointProvider>
		<BrowserRouter>
			<Routes>
				<Route
					path={PATH_PARAMS.ROOT_APP_PATH}
					element={
						<React.StrictMode>
							<ConsentPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.CONSULTATION_PATH}
					element={
						<React.StrictMode>
							<FormViewerPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.SHIPPING_ADDRESS_PATH}
					element={
						<React.StrictMode>
							<ShippingAddressPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.PHARMACY_PICKER_PATH}
					element={
						<React.StrictMode>
							<PharmacyPickerPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.SUCCESS_PAGE_PATH}
					element={
						<React.StrictMode>
							<SuccessPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.DENIAL_PAGE_PATH}
					element={
						<React.StrictMode>
							<DenialPage />
						</React.StrictMode>
					}
				/>
				<Route
					path={PATH_PARAMS.SUMMARY_PAGE}
					element={
						<React.StrictMode>
							<ConsultationSummary />
						</React.StrictMode>
					}
				/>
			</Routes>
		</BrowserRouter>
	</ExitPointProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
