import { TFormEnableCondition } from "./types/FormElement";

export class FormEnableCondition implements TFormEnableCondition {
	question: string;
	operator: string;
	answerBoolean?: boolean | undefined;
	answerDecimal?: number | undefined;
	answerInteger?: number | undefined;
	answerDate?: string | undefined;
	answerDateTime?: string | undefined;
	answerTime?: string | undefined;
	answerString?: string | undefined;
	answerCoding?: string | undefined;
	answerQuantity?: number | undefined;
	answerReference?: string | undefined;

	constructor(
		question: string,
		operator: string,
		answerBoolean: boolean | undefined = undefined,
		answerDecimal: number | undefined = undefined,
		answerInteger: number | undefined = undefined,
		answerDate: string | undefined = undefined,
		answerDateTime: string | undefined = undefined,
		answerTime: string | undefined = undefined,
		answerString: string | undefined = undefined,
		answerCoding: string | undefined = undefined,
		answerQuantity: number | undefined = undefined,
		answerReference: string | undefined = undefined
	) {
		this.question = question;
		this.operator = operator;
		this.answerBoolean = answerBoolean;
		this.answerDecimal = answerDecimal;
		this.answerInteger = answerInteger;
		this.answerDate = answerDate;
		this.answerDateTime = answerDateTime;
		this.answerTime = answerTime;
		this.answerString = answerString;
		this.answerCoding = answerCoding;
		this.answerQuantity = answerQuantity;
		this.answerReference = answerReference;
	}

	// Not clean, revisit this later. Only of these will be populated at all times.
	getExpectedAnswer(): any {
		if (this.answerBoolean !== undefined) return this.answerBoolean;
		if (this.answerDecimal !== undefined) return this.answerDecimal;
		if (this.answerInteger !== undefined) return this.answerInteger;
		if (this.answerDate !== undefined) return this.answerDate;
		if (this.answerDateTime !== undefined) return this.answerDateTime;
		if (this.answerTime !== undefined) return this.answerTime;
		if (this.answerString !== undefined) return this.answerString;
		if (this.answerCoding !== undefined) return this.answerCoding;
		if (this.answerQuantity !== undefined) return this.answerQuantity;
		if (this.answerReference !== undefined) return this.answerReference;
	}

	static parseEnableCondition(conditions: any[] | undefined): FormEnableCondition[] | undefined {
		if (conditions === undefined) {
			return undefined;
		}

		const parsedCondition = [];
		if (conditions.length > 0) {
			for (let option of conditions) {
				parsedCondition.push(
					new FormEnableCondition(
						option.question,
						option.operator,
						option.answerBoolean,
						option.answerDecimal,
						option.answerInteger,
						option.answerDate,
						option.answerDateTime,
						option.answerTime,
						option.answerString,
						option.answerCoding,
						option.answerQuantity,
						option.answerReference
					)
				);
			}
		}
		return parsedCondition;
	}
}
