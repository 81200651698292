import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CONSTANTS, STATES } from "../../utility/constants";
import { constructQueryParams } from "../../utility/stringUtils";
import { questionnaireUrl } from "../../utility/questionnaireEndpoint";
import { FormValue } from "../../domain/FormValue";
import { FormGeneratorProps } from "../../components/form-components/form-generator/form-generator";

import PageWrapper from "../../components/page-wrapper/page-wrapper";

const ShippingAddressPage = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { consultId, zipCode } = useParams();
	const [queryParams] = useSearchParams();
	const [procedure, setProcedure] = useState<string>("");
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [mode, setMode] = useState("");
	const [address, setAddress] = useState({
		addressLine1: "",
		addressLine2: "",
		city: "",
		state: "",
		postalCode: ""
	});

	const [addressError, setAddressError] = useState<string>("");

	// State tracking variables
	const [consent, setConsent] = useState<boolean>();
	const [maxStep, setMaxStep] = useState(0);
	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);

	const origin = "shipping";

	const onChangeHandler = (event: any) => {
		console.log(event.target.name);
		setAddress({
			...address,
			[event.target.name]: event.target.value
		});
	};

	const navigateTo = (path: string) => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		const currentState = {
			formValues: formValues,
			consent: consent,
			pharmacy: null,
			maxStep: maxStep,
			origin: origin,
			address: address
		};

		if (path === "review") {
			if (address.addressLine1 && address.city && address.state && address.postalCode) {
				navigate(`/${zipCode}/consult/${consultId}/summary${queryParams}`, { state: currentState });
			} else {
				setAddressError("Please fill out all required fields");
			}
		}

		if (path === "questionnaire") {
			navigate(`/${zipCode}/consult/${consultId}/start/questionnaire${queryParams}`, {
				state: currentState
			});
		}
	};

	useEffect(() => {
		const procedureParam = queryParams.get("procedure") as keyof typeof questionnaireUrl;
		if (procedureParam) setProcedure(`procedure=${procedureParam}`);

		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) setRedirectUri(`redirect_uri=${redirectUri}`);

		const modeParam = queryParams.get("mode");
		if (modeParam) setMode(`mode=${modeParam}`);
	}, [queryParams]);

	useEffect(() => {
		if (state) {
			const { consent, formValues, maxStep, patientAddress } = state as FormGeneratorProps;
			setConsent(consent || false);
			setFormValues(formValues);
			setMaxStep(maxStep || formValues.length);

			if (patientAddress !== undefined) {
				setAddress({
					addressLine1: patientAddress.line[0],
					addressLine2: patientAddress.line[1] || "",
					city: patientAddress.city,
					state: patientAddress.state,
					postalCode: patientAddress.postalCode
				});
			}
		}
	}, [state]);

	return (
		<PageWrapper enabled={true} id="shipping-address-page-wrapper">
			<h2>{CONSTANTS.SHIPPING_HEADER}</h2>
			<div className="mt-1">
				<div className="mt-1">
					<TextField
						sx={{ width: 1 }}
						id="addressLine1"
						name="addressLine1"
						label="Street Address"
						value={address.addressLine1}
						onChange={onChangeHandler}
						inputProps={{ "data-testid": "addressLine1" }}
						autoComplete="false"
						data-testid={"text-input-parent-addressLine1"}
						required={true}
					/>
				</div>
				<div className="mt-1">
					<TextField
						sx={{ width: 1 }}
						id="addressLine2"
						name="addressLine2"
						label="Suite/Apt #"
						value={address.addressLine2}
						onChange={onChangeHandler}
						inputProps={{ "data-testid": "addressLine2" }}
						autoComplete="false"
						data-testid={"text-input-parent-addressLine2"}
					/>
				</div>
				<div className="mt-1">
					<TextField
						sx={{ width: 1 }}
						id="city"
						name="city"
						label="City"
						value={address.city}
						onChange={onChangeHandler}
						inputProps={{ "data-testid": "city" }}
						autoComplete="false"
						data-testid={"text-input-parent-city"}
						required={true}
					/>
				</div>
				<div className="mt-1">
					<FormControl sx={{ minWidth: 120, width: 1 }}>
						<InputLabel id="state-label">State *</InputLabel>
						<Select
							labelId="state-label"
							id="state"
							name="state"
							value={address.state}
							label="State"
							onChange={onChangeHandler}
							required={true}
						>
							{STATES.map((s: any) => {
								return (
									<MenuItem key={s.value} value={s.value}>
										{s.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</div>
			<div className="mt-1">
				<TextField
					sx={{ width: 1 }}
					id="postalCode"
					name="postalCode"
					label="Zip Code"
					value={address.postalCode}
					onChange={onChangeHandler}
					inputProps={{ "data-testid": "postalCode" }}
					autoComplete="false"
					data-testid={"text-input-parent-postalCode"}
					required={true}
				/>
			</div>

			<div className="mt-2">
				{addressError && (
					<Alert severity="warning" sx={{ mb: 4 }}>
						{addressError}
					</Alert>
				)}
			</div>

			<div className="form-nav-button-container mt-2">
				<Button
					id="prev"
					variant="outlined"
					className="form-nav-button left"
					onClick={() => navigateTo("questionnaire")}
					data-testid={"prev"}
				>
					<ChevronLeft fontSize="inherit" />
					Previous
				</Button>
				<Button
					id="navToReview"
					variant="outlined"
					className="form-nav-button right"
					onClick={() => navigateTo("review")}
					data-testid={"navToReview"}
				>
					Continue
					<ChevronRight fontSize="inherit" />
				</Button>
			</div>
		</PageWrapper>
	);
};

export default ShippingAddressPage;
