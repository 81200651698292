import { FormValue } from "../domain/FormValue";
import { CONSTANTS } from "./constants";

export const compareItems = (actualAnswer: any, operator: string, expectedAnswer: any) => {
	const answerToEvaluate = Array.isArray(actualAnswer) ? actualAnswer[0] : actualAnswer;
	switch (operator) {
		case "=":
			return compareEquals(answerToEvaluate, expectedAnswer);
		case "!=":
			return answerToEvaluate !== expectedAnswer;
		case ">":
			return answerToEvaluate > expectedAnswer;
		case "<":
			return answerToEvaluate < expectedAnswer;
		case ">=":
			return answerToEvaluate >= expectedAnswer;
		case "<=":
			return answerToEvaluate <= expectedAnswer;
		case "exists":
			return actualAnswer.includes(expectedAnswer); //This would be for multiple answers, so we're keeping actualAnswer
	}
};

function compareEquals(actualValue: any, expectedValue: any) {
	if (Array.isArray(actualValue)) return actualValue.includes(expectedValue);
	return actualValue === expectedValue;
}

const valuePresent = (formValue: FormValue | undefined): boolean => {
	if (formValue !== null && formValue !== undefined) {
		const value = formValue.value;

		if (typeof value === CONSTANTS.STRING_TYPE || value instanceof String) {
			return value !== "";
		}
		if (value instanceof Array) {
			return value.length > 0;
		}

		// This covers 'object' and 'boolean'
		return true;
	}

	return false;
};

export const hasRequiredValue = (element: JSX.Element, formValues: FormValue[]) => {
	const linkId = element.props.item.linkId;
	const formValue: FormValue | undefined = formValues.find((item: FormValue) => item.key === linkId);

	const hasValue = valuePresent(formValue);
	const isRequired = element.props.item.required || false;
	const isEnabled = element.props.enabled || false;

	if (isEnabled && isRequired && !hasValue) {
		return false;
	}

	return true;
};
