import { TFormAnswerOption } from "./types/FormElement";

export class FormAnswerOption implements TFormAnswerOption {
	valueString: string;

	constructor(value: string) {
		this.valueString = value;
	}

	static parseAnswerOptions(answerOptions: any[] | undefined): FormAnswerOption[] | undefined {
		if (answerOptions === undefined) {
			return undefined;
		}

		let parsedOptions: FormAnswerOption[] = [];
		if (answerOptions.length > 0) {
			for (let option of answerOptions) {
				parsedOptions.push(new FormAnswerOption(option.valueString));
			}
		}
		return parsedOptions;
	}
}
