type QuestionnaireEndpoints = {
	"teco-obgyn-vph"?: string;
	"teco-teststrip-uti"?: string;
	"covid"?: string;
};

export const questionnaireUrl: QuestionnaireEndpoints = {
	"teco-obgyn-vph": process.env.REACT_APP_MOCK_GET_VPH_QUESTIONNAIRE_URL,
	"teco-teststrip-uti": process.env.REACT_APP_MOCK_GET_UTI_QUESTIONNAIRE_URL,
	"covid": process.env.REACT_APP_GET_COVID_QUESTIONNAIRE_URL
};
