export class SearchPharmacyRequest {
	searchValue: string;
	searchDistance: number | string;
	pharmacyName: string;

	constructor(searchValue: string = "", searchDistance: number | string = 5, pharmacyName: string = "") {
		this.searchValue = searchValue;
		this.searchDistance = searchDistance;
		this.pharmacyName = pharmacyName;
	}

	isValid = (): boolean => {
		if (this.searchValue && this.pharmacyName && this.searchDistance && this.searchDistance > 0) {
			return true;
		}

		return false;
	};
}
