export class PharmacyService {
	static getSearchPharmacyUrl = () => {
		return process.env.REACT_APP_SEARCH_PHARMACY_URL;
	};
	static getGovPharmaciesUrl = () => {
		return process.env.REACT_APP_GOV_PHARMACY_SEARCH_URL;
	};
	static getPharmacyPrescriptionAppToken = () => {
		return process.env.REACT_APP_GOV_PHARMACY_SEARCH_APP_TOKEN;
	};
}
