import { FormAnswerOption } from "./FormAnswerOption";
import { FormEnableCondition } from "./FormEnableCondition";
import TFormElement from "./types/FormElement";

export class FormItem implements TFormElement {
	linkId: string;
	prefix: string;
	text: string;
	type: string;
	enableWhen?: FormEnableCondition[] | undefined;
	answerOption?: FormAnswerOption[] | undefined;
	required?: boolean | undefined;
	repeats?: boolean | undefined;

	constructor(
		linkId: string,
		prefix: string,
		text: string,
		type: string,
		enableWhen: FormEnableCondition[] | undefined = undefined,
		answerOption: FormAnswerOption[] | undefined = undefined,
		required: boolean | undefined = undefined,
		repeats: boolean | undefined = undefined
	) {
		this.linkId = linkId;
		this.prefix = prefix;
		this.text = text;
		this.type = type;
		this.enableWhen = enableWhen;
		this.answerOption = answerOption;
		this.required = required;
		this.repeats = repeats;
	}

	static parseFormItem(item: any): FormItem {
		let enableWhen = undefined;
		let answerOption = undefined;
		let required = undefined;
		let repeats = undefined;
		if (item.enableWhen !== null || item.enableWhen !== undefined) {
			enableWhen = FormEnableCondition.parseEnableCondition(item.enableWhen);
		}

		if (item.answerOption !== null || item.answerOption !== undefined) {
			answerOption = FormAnswerOption.parseAnswerOptions(item.answerOption);
		}

		if (item.required !== null || item.required !== undefined) {
			required = item.required;
		}
		if (item.repeats !== null || item.repeats !== undefined) {
			repeats = item.repeats;
		}
		return new FormItem(
			item.linkId,
			item.prefix,
			item.text,
			item.type,
			enableWhen,
			answerOption,
			required,
			repeats
		);
	}

	hasEnabledCondition(): boolean {
		if (this.enableWhen !== undefined && this.enableWhen.length > 0) {
			return true;
		}
		return false;
	}
}
