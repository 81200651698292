export class Pharmacy {
	pharmacyId: string;
	pharmacyName: string;
	pharmacyPhone: string;
	streetAddress: string;
	streetAddress2: string;
	city: string;
	state: string;
	zipCode: string;

	constructor(
		pharmacyId: string,
		pharmacyName: string,
		pharmacyPhone: string,
		streetAddress: string,
		streetAddress2: string,
		city: string,
		state: string,
		zipCode: string
	) {
		this.pharmacyId = pharmacyId;
		this.pharmacyName = pharmacyName;
		this.pharmacyPhone = pharmacyPhone;
		this.streetAddress = streetAddress;
		this.streetAddress2 = streetAddress2;
		this.city = city;
		this.state = state;
		this.zipCode = zipCode;
	}

	static parsePharmacies = (pharmaciesJson: any[]): Pharmacy[] => {
		const pharmacies: Pharmacy[] = [];
		for (const pharma of pharmaciesJson) {
			pharmacies.push(Pharmacy.parsePharmacy(pharma));
		}
		const orderedPhamarcies = pharmacies.sort((a: Pharmacy, b: Pharmacy) =>
			a.pharmacyName.localeCompare(b.pharmacyName)
		);
		return orderedPhamarcies;
	};

	static parsePharmacy = (pharmacyJson: any) => {
		return new Pharmacy(
			pharmacyJson.pharmacyId,
			pharmacyJson.pharmacyName,
			pharmacyJson.pharmacyPhone,
			pharmacyJson.streetAddress,
			pharmacyJson.streetAddress2,
			pharmacyJson.city,
			pharmacyJson.stateAbbrev,
			pharmacyJson.zipCode
		);
	};

	static getFullAddress = (pharmacy: Pharmacy | undefined): string => {
		if (pharmacy) {
			if (pharmacy.streetAddress2) {
				return `${pharmacy.streetAddress} ${pharmacy.streetAddress2} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`;
			} else {
				return `${pharmacy.streetAddress} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`;
			}
		} else {
			return "";
		}
	};
}
