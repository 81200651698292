import { useCallback, useState } from "react";
import { HTTP_METHODS } from "../../utility/constants";

export type RequestConfig = {
	url: string;
	method?: string;
	headers?: any;
	body?: any;
};

const useHttp = () => {
	const [data, setData] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const sendRequest = useCallback(async (config: RequestConfig) => {
		const { url, method, headers, body } = config;
		setLoading(true);
		setError("");
		if (url) {
			try {
				const response = await fetch(url, {
					method: method ? method : HTTP_METHODS.GET,
					headers: headers ? headers : {},
					body: body ? JSON.stringify(body) : null
				});

				if (!response.ok) {
					throw new Error("Request failed");
				}

				const data = await response.json();
				setData(data);
				setLoading(false);

				return data;
			} catch (error: any) {
				setLoading(false);
				setError(error.message || "Something went wrong!");
			}
		}
	}, []);

	return {
		data,
		loading,
		error,
		sendRequest
	};
};

export default useHttp;
