import React, { createContext, useContext, useState } from "react";

export type ExitPointContextType = {
	setExitPoint?: React.Dispatch<React.SetStateAction<string>>;
	exitPoint?: string | undefined;
};

const ExitPointContext = createContext<ExitPointContextType | undefined | null>(null);

const useExitPoint = () => useContext(ExitPointContext);

function ExitPointProvider(props: any) {
	const [exitPoint, setExitPoint] = useState("");

	const exitPointStore = {
		exitPoint,
		setExitPoint
	};
	return <ExitPointContext.Provider value={exitPointStore}>{props.children}</ExitPointContext.Provider>;
}

export { ExitPointProvider, useExitPoint };
