export class QuestionnaireAnswersPayload {
	id: string | undefined;
	resourceType: string;
	status: string;
	pharmacyID: string;
	item: any;
	constructor(id: string | undefined, resourceType: string, status: string, pharmacyID: string, item: any) {
		this.id = id;
		this.resourceType = resourceType;
		this.status = status;
		this.pharmacyID = pharmacyID;
		this.item = item;
	}
}
