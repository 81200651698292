import { useEffect } from "react";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";

import "./DenialPage.css";

const DenialPage: React.FC = (): JSX.Element => {
	const { state } = useLocation();
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const rejectionCause = state as string[];

	useEffect(() => {
		if (setExitPoint) setExitPoint("rejection_page");
	}, [setExitPoint]);

	return (
		<PageWrapper enabled={true} id="denial-page">
			<h2>Based on your answers, we are not able to offer you treatment at this time.</h2>

			<span className="span-subtitle">
				We do suggest that you consider further evaluation in person with your doctor, at an urgent care center,
				or other care location.
			</span>

			<h3>We came to this determination because:</h3>
			<div>
				{rejectionCause &&
					rejectionCause.length &&
					rejectionCause.map((cause, idx) => {
						return (
							<Alert severity="error" sx={{ mb: 2 }} key={`${idx}-error`}>
								{cause.split(":")[1] ? cause.split(":")[1].trim() : cause}
							</Alert>
						);
					})}
			</div>
		</PageWrapper>
	);
};

export default DenialPage;
