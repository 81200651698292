export class Address {
	line: string[];
	city: string;
	state: string;
	postalCode: string;

	constructor(line: string[], city: string, state: string, postalCode: any) {
		this.line = line;
		this.city = city;
		this.state = state;
		this.postalCode = postalCode;
	}
}
