import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useState } from "react";
import { Button } from "@mui/material";
import { matchRoutes, useLocation, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../utility/constants";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";

import "./logo-banner.css";

const LogoBanner = () => {
	const [open, setOpen] = useState(false);
	const [queryParams] = useSearchParams();
	const location = useLocation();
	const { exitPoint } = useExitPoint() as ExitPointContextType;

	const hiddenLocations = matchRoutes(
		[{ path: PATH_PARAMS.SUCCESS_PAGE_PATH }, { path: PATH_PARAMS.DENIAL_PAGE_PATH }],
		location
	);

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleExit = (): void => {
		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) {
			window.location.href = `${redirectUri}?result=cancelled&exitPoint=${exitPoint}`;
		} else {
			handleClose();
		}
	};

	return (
		<div className={!hiddenLocations ? "banner-container" : "banner-container center"}>
			<div id="logo-banner" data-testid="logo-banner" className="mr-2"></div>
			{!hiddenLocations && (
				<Button
					variant="outlined"
					className="exit-button right"
					data-testid="exit-button"
					onClick={handleClickOpen}
				>
					Exit
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="bold">
					COVID-19 CAN BE A SERIOUS ILLNESS
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You are only a few clicks away from seeing if you qualify for free treatment. You can lose
						access if you exit now.
					</DialogContentText>
				</DialogContent>
				<DialogActions className="button-container modal-button-container flex-wrap">
					<Button variant="outlined" className="cancel-button left" onClick={() => handleExit()}>
						I'm not interested in free treatment
					</Button>
					<Button className="modal-exit-button form-nav-button right" onClick={handleClose} autoFocus>
						Get Free Treatment
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default LogoBanner;
