import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

import "./search-input.css";

export type SearchInputProps = {
	onChangeHandler: (value: string) => void;
	label: string;
	value?: string;
};

const SearchInput = (props: SearchInputProps) => {
	return (
		<TextField
			label={props.label}
			id="search"
			data-testid={props.label}
			sx={{ m: 0, width: 1, borderRadius: 0 }}
			onChange={(event) => {
				props.onChangeHandler(event.target.value);
			}}
			value={props.value}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search></Search>
					</InputAdornment>
				)
			}}
		/>
	);
};

export default SearchInput;
