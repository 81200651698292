import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

const DateInput = (props: any) => {
	const [value, setValue] = useState<Date | undefined>(undefined);
	const {
		item: { linkId, text, type }
	} = props;

	useEffect(() => {
		if (props.value !== null && props.value !== undefined) {
			setValue(props.value);
		} else {
			setValue(new Date(2022, 0, 1));
		}
	}, [props.value]);

	return (
		<div className={props.enabled ? "" : "hidden"}>
			<h2 className="mb-0">{text}</h2>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					value={value}
					onChange={(val: any) => {
						setValue(val);
						props.onChangeHandler(linkId, text, type, val);
					}}
					renderInput={(params) => (
						<TextField autoComplete="false" id={linkId} sx={{ mt: 4, mb: 4, width: 1 }} {...params} />
					)}
				/>
			</LocalizationProvider>
		</div>
	);
};

export default DateInput;
