import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Alert, Backdrop, Button, CircularProgress, TextField, Pagination } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultButton } from "../../components/default-button/default-button";
import { PharmacyService } from "../../api/pharmacy-service";
import { FormValue } from "../../domain/FormValue";
import { Pharmacy } from "../../domain/Pharmacy";
import { SearchPharmacyRequest } from "../../domain/request/SearchPharmacyRequest";
import {
	ERROR_MESSAGES,
	FETCH_HEADERS,
	HTTP_METHODS,
	CONSTANTS,
	EventType,
	INTAKE_STEP,
	DRUG_TYPE
} from "../../utility/constants";
import { format } from "date-fns";
import { QuestionnaireService } from "../../api/questionnaire-service";
import { QuestionnaireAnswersPayload } from "../../domain/QuestionnaireAnswersPayload";
import { FORM_RESPONSE_STATUS } from "../../domain/types/FormElement";
import { FormGeneratorProps } from "../../components/form-components/form-generator/form-generator";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { updateProgress } from "../../utility/metricsUtils";
import { constructQueryParams } from "../../utility/stringUtils";
import PharmacyOption from "../../components/pharmacy-option/pharmacy-option";
import SearchInput from "../../components/search-input/search-input";
import useHttp, { RequestConfig } from "../../hooks/use-http/use-http";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

import "./PharmacyPickerPage.css";

const PharmacyPickerPage = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setExitPoint } = useExitPoint() as ExitPointContextType;

	const { consultId, zipCode } = useParams();
	const { error, sendRequest: getPharmacies } = useHttp();
	const { sendRequest: getGovPharmacies } = useHttp();
	const { sendRequest } = useHttp();

	const DEFAULT_DISTANCE = 3;
	const ITEMS_PER_PAGE = 10;

	const [loading, setLoading] = React.useState<boolean>(false);
	const [pharmacyList, setPharmacyList] = React.useState<Pharmacy[]>([]);
	const [selectedPharmacy, setSelectedPharmacy] = React.useState<Pharmacy | null>(null);
	const [searchInput, setSearchInput] = useState(zipCode);
	const [searchDistance, setSearchDistance] = useState<number | string>(DEFAULT_DISTANCE);
	const [pharmacyName, setPharmacyName] = useState("");
	const [isSearched, setIsSearched] = useState(false);

	const [selectionError, setSelectionError] = useState<string | null>(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [procedure, setProcedure] = useState<string>("");
	const searchPharmacyUrl = PharmacyService.getSearchPharmacyUrl();
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const isBrowser = typeof window !== "undefined";
	const [mode, setMode] = useState("");

	// State tracking variables
	const [consent, setConsent] = useState<boolean>();
	const [maxStep, setMaxStep] = useState(0);
	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);

	const [redirectUri, setRedirectUri] = useState<string>("");
	const [queryParams] = useSearchParams();
	const procedureParam = queryParams.get("procedure");
	const modeParam = queryParams.get("mode");

	const headers = useMemo(() => {
		return { ...FETCH_HEADERS, "X-App-Token": PharmacyService.getPharmacyPrescriptionAppToken() };
	}, []);

	const urlGov = PharmacyService.getGovPharmaciesUrl();
	const govconfig = useMemo(() => {
		return {
			url: urlGov,
			method: HTTP_METHODS.GET,
			headers
		};
	}, [headers, urlGov]);

	const pharmacyChangeHandler = (selectedPharmacy: Pharmacy) => {
		pharmacyList.forEach((pharmacy: Pharmacy) => {
			if (pharmacy.pharmacyId === selectedPharmacy.pharmacyId) {
				setSelectedPharmacy(pharmacy);
				setSelectionError(null);
			}
		});
	};

	const searchInputChangeHandler = (value: string) => setSearchInput(value);

	const distanceChangeHandler = (event: any) => setSearchDistance(event.target.value);

	const pharmacyNameChangeHandler = (event: any) => setPharmacyName(event.target.value);

	const paginationChangeHandler = (event: any, page: number) => {
		setCurrentPage(page);
	};

	const navigateTo = (path: string) => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		const currentState = {
			formValues: formValues,
			consent: consent,
			pharmacy: selectedPharmacy,
			maxStep: maxStep,
			origin: "pharmacy"
		};

		navigate(`/${zipCode}/consult/${consultId}/${path}${queryParams}`, {
			state: currentState
		});
	};

	const handleSubmit = () => {
		if (modeParam && modeParam === "demo") {
			if (selectedPharmacy?.pharmacyId && selectedPharmacy.pharmacyId !== undefined) {
				navigateTo("summary");
			} else {
				setSelectionError("Please choose a pharmacy of your preference to continue");
			}
			return;
		}

		const parsedAnswer = (answer: any) => {
			if (Array.isArray(answer)) {
				return answer.join(", ");
			}
			return answer.toString();
		};

		const questionItem = formValues?.map((question) => {
			const finalValue = question.type === "date" ? format(question.value, "MM-dd-yyyy") : question.value;
			return {
				linkId: question.key,
				text: question.text,
				answer: parsedAnswer(finalValue)
			};
		});

		if (selectedPharmacy?.pharmacyId && selectedPharmacy.pharmacyId !== undefined) {
			const payloadObj = new QuestionnaireAnswersPayload(
				consultId,
				CONSTANTS.QUESTIONNAIRE_REPONSE,
				FORM_RESPONSE_STATUS.IN_PROGRESS,
				selectedPharmacy.pharmacyId,
				questionItem
			);

			// Make POST to GGM's API
			postRequest(payloadObj);
		} else {
			setSelectionError("Please choose a pharmacy of your preference to continue");
		}
	};

	const postRequest = async (payload: QuestionnaireAnswersPayload) => {
		setLoading(true);

		const config = {
			url: QuestionnaireService.getFinalizeQuestionnaireUrl(),
			method: HTTP_METHODS.POST,
			body: payload,
			headers: FETCH_HEADERS
		} as RequestConfig;

		const response = await sendRequest(config);
		setLoading(false);

		if (response && response.success) {
			navigate(`/${zipCode}/consult/success`, { state: { pharmacy: selectedPharmacy } });
		} else if (response && !response.success) {
			navigate(`/${zipCode}/consult/rejection`, { state: response.errors });
		} else {
			navigate(`/${zipCode}/consult/rejection`, {
				state: ["Server error, please try again soon"]
			});
		}
	};
	const fetchGovPharmacies = useCallback(
		async (stateAbb: string) => {
			return await getGovPharmacies({ ...govconfig, url: `${urlGov}?state_code=${stateAbb}` });
		},
		[getGovPharmacies, govconfig, urlGov]
	);

	const fetchPharmacies = useCallback(
		async (config: any) => {
			setLoading(true);
			const response = await getPharmacies(config);

			if (procedureParam === "teco-obgyn-vph" || procedureParam === "teco-teststrip-uti") {
				if (response.Value.length) {
					setPharmacyList(Pharmacy.parsePharmacies(response.Value));
				} else {
					setLoading(false);
				}
			} else {
				if (response && response.success && response.Value.length !== 0) {
					const stateAbb = response.Value[0].stateAbbrev;
					const govResponse = await fetchGovPharmacies(stateAbb);

					// Filter the list of pharmacies based on pharms that carry Paxlovid available
					if (govResponse && govResponse.length) {
						const matchingPharmacies = response.Value.filter((pharm: { pharmacyNPI: string }) =>
							govResponse.some(
								(govPharm: { npi: string; order_label: string }) =>
									pharm.pharmacyNPI === govPharm.npi && govPharm.order_label === DRUG_TYPE.PAXLOVID
							)
						);

						if (matchingPharmacies.length) {
							setLoading(false);
							setPharmacyList(Pharmacy.parsePharmacies(matchingPharmacies));
						} else {
							setSelectionError(ERROR_MESSAGES.PHARMACY_SEARCH_ERROR);
							setPharmacyList([]);
						}
					}
				}
			}
			setLoading(false);
			setIsSearched(true);
		},
		[fetchGovPharmacies, getPharmacies, procedureParam]
	);

	const searchPharmacy = useCallback(() => {
		const distance = !searchDistance ? DEFAULT_DISTANCE : searchDistance;
		const payload = new SearchPharmacyRequest(searchInput, distance, pharmacyName);
		const config = {
			url: searchPharmacyUrl,
			body: payload,
			method: HTTP_METHODS.POST,
			headers: FETCH_HEADERS
		};

		fetchPharmacies(config);
	}, [fetchPharmacies, pharmacyName, searchDistance, searchInput, searchPharmacyUrl]);

	useEffect(() => {
		const config = {
			url: searchPharmacyUrl,
			body: new SearchPharmacyRequest(zipCode, DEFAULT_DISTANCE, ""),
			method: HTTP_METHODS.POST,
			headers: FETCH_HEADERS
		};
		fetchPharmacies(config);

		if (state) {
			const { consent, formValues, maxStep } = state as FormGeneratorProps;
			setConsent(consent || false);
			setFormValues(formValues);
			setMaxStep(maxStep || formValues.length);
		}

		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) {
			setRedirectUri(`redirect_uri=${redirectUri}`);
		}

		if (procedureParam) {
			setProcedure(`procedure=${procedureParam}`);
		}
		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [
		state,
		searchPharmacyUrl,
		zipCode,
		fetchPharmacies,
		setRedirectUri,
		queryParams,
		govconfig,
		procedureParam,
		modeParam
	]);

	useEffect(() => {
		updateProgress(EventType.STEP_CHANGE, consultId, INTAKE_STEP.PHARMACY_PICKER_PAGE, sendRequest);
	}, [consultId, sendRequest]);

	const handleWindowSizeChange = () => {
		if (isBrowser) {
			if (window.innerWidth <= 500) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		}
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("pharmacy_picker_page");
		handleWindowSizeChange();
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterPage = (element: any, index: number) => {
		const from = (currentPage - 1) * ITEMS_PER_PAGE;
		const to = ITEMS_PER_PAGE * currentPage - 1;
		return index >= from && index <= to;
	};

	return (
		<PageWrapper enabled={true} id="pharmacy-picker-page-wrapper">
			<Backdrop open={loading} sx={{ zIndex: 11 }} data-testid="loader">
				<CircularProgress sx={{ color: "#FFF" }} />
			</Backdrop>
			<h2>Select A Pharmacy</h2>
			<span>
				Choose where you'd like to pick up your medication if your healthcare professional writes you a
				perscription.
			</span>
			<div className="search-parameter-container">
				<div className="pharmacy-name">
					<TextField
						sx={{ width: 1 }}
						id="pharmacy-name"
						label="Pharmacy name"
						onChange={pharmacyNameChangeHandler}
						inputProps={{ "data-testid": "pharmacy-name" }}
						autoComplete="false"
						data-testid={"text-input-parent-pharmacy-name"}
					/>
				</div>
				<div className="search-distance">
					<TextField
						sx={{ width: 1 }}
						id="search-distance"
						label="Search distance (in miles)"
						value={searchDistance}
						onChange={distanceChangeHandler}
						inputProps={{ "data-testid": "search-distance" }}
						autoComplete="false"
						data-testid={"text-input-parent-search-distance"}
					/>
				</div>
			</div>

			<div className="search">
				<SearchInput
					onChangeHandler={searchInputChangeHandler}
					label="Address or Zip Code"
					value={searchInput}
				/>
			</div>

			<DefaultButton buttonTitle="Search Pharmacies" onClick={searchPharmacy} style={{ width: 1, mt: "2rem" }} />

			<div className="centered-col-flex-container">
				{error !== "" || (pharmacyList.length === 0 && isSearched) ? (
					<Alert severity="warning" sx={{ my: 4 }}>
						{ERROR_MESSAGES.PHARMACY_SEARCH_ERROR}
					</Alert>
				) : (
					pharmacyList.filter(filterPage).map((pharma: Pharmacy) => {
						return (
							<PharmacyOption
								key={pharma.pharmacyId}
								pharmacy={pharma}
								onChangeHandler={pharmacyChangeHandler}
								active={selectedPharmacy?.pharmacyId === pharma.pharmacyId}
							/>
						);
					})
				)}
			</div>
			<div>
				{selectionError && (
					<Alert severity="warning" sx={{ mb: 4 }}>
						{selectionError}
					</Alert>
				)}
			</div>
			<div className="pagination-container">
				<Pagination
					count={Math.ceil(pharmacyList.length / ITEMS_PER_PAGE)}
					onChange={paginationChangeHandler}
					size={isMobile ? "large" : "small"}
				/>
			</div>
			<div className="form-nav-button-container">
				<Button
					id="prev"
					variant="outlined"
					className="form-nav-button left"
					onClick={() => navigateTo("start/questionnaire")}
					data-testid={"prev"}
				>
					<ChevronLeft fontSize="inherit" />
					Previous
				</Button>
				<Button
					id="submit"
					variant="outlined"
					className="form-nav-button right"
					onClick={handleSubmit}
					data-testid={"submit"}
				>
					{/* Once this changes are fully implements, this button will only display Continue */}
					{modeParam === "demo" ? "Continue" : "Submit"}
					<ChevronRight fontSize="inherit" />
				</Button>
			</div>
		</PageWrapper>
	);
};

export default PharmacyPickerPage;
