import { EventData, UpdateIntakeProgressRequest } from "../domain/request/UpdateIntakeProgressRequest";
import { RequestConfig } from "../hooks/use-http/use-http";
import { EventType, FETCH_HEADERS, HTTP_METHODS } from "./constants";

export const updateProgress = async (
	eventType: EventType,
	consultId: string | undefined,
	intakeStep: string,
	sendRequest: (config: RequestConfig) => Promise<any>
) => {
	const url = process.env.REACT_APP_UPDATE_INTAKE_URL;
	if (url && consultId) {
		const updateIntakeProcessRequest = new UpdateIntakeProgressRequest(
			eventType,
			new Date().toISOString(),
			new EventData(consultId, intakeStep)
		);

		const config = {
			url: url,
			method: HTTP_METHODS.POST,
			body: updateIntakeProcessRequest,
			headers: FETCH_HEADERS
		} as RequestConfig;

		sendRequest(config);
	}
};
