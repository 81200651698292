const trimInputLabel = (label: string): string => {
	if (label.length > 47) {
		label = label.slice(0, 47) + "...";
	}

	return label;
};

const getLabelId = (label: string): string => {
	return label + "-label";
};

const trimAllWhiteSpace = (str: string) => {
	return str.replace(/\s/g, "");
};

const parseURL = (url: string | undefined, variables: any = []) => {
	if (!url) {
		return "";
	}

	let parsedURL = url;

	Object.keys(variables).forEach((key) => {
		parsedURL = parsedURL.replace(`:${key}`, variables[key]);
	});

	return parsedURL;
};

const replaceIdDelimiter = (str: string) => {
	return str.replace(".", "-");
};

const constructQueryParams = (params: string[]) => {
	const filtered = params.filter((val: string) => val !== "" && val !== null && val !== undefined);
	const queryString = filtered.join("&");
	return `?${queryString}`;
};

const trimAndLowerCaseString = (str: string) => {
	return str.toLowerCase().replace(/\s/g, "");
};

export {
	trimInputLabel,
	getLabelId,
	trimAllWhiteSpace,
	parseURL,
	replaceIdDelimiter,
	constructQueryParams,
	trimAndLowerCaseString
};
