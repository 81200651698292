import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CONSTANTS } from "../../../utility/constants";
import { replaceIdDelimiter } from "../../../utility/stringUtils";

const YesNoInput = (props: any) => {
	const [active, setActive] = useState<boolean | undefined>(undefined);
	const {
		item: { linkId, text, type }
	} = props;

	const shouldBeActive = (button: string) => {
		if (button === "yes" && active !== undefined && active) {
			return true;
		}
		if (button === "no" && active !== undefined && !active) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (props.value !== null && props.value !== undefined) {
			setActive(props.value);
		}
	}, [props.value]);

	const checkMark = (active: boolean) => {
		return (
			<div className={active ? "radio-checks radio-btn selected" : "radio-checks radio-btn"}>
				<div />
			</div>
		);
	};

	return (
		<div className={props.enabled ? "" : "hidden"}>
			<span>{text}</span>
			<div className="button-container mb-2">
				<Button
					id={replaceIdDelimiter(linkId) + "-no"}
					data-testid={linkId + "-no"}
					variant="outlined"
					className={"button " + (shouldBeActive("no") ? "button-active" : "")}
					onClick={() => {
						setActive(false);
						props.onChangeHandler(linkId, text, type, false);
					}}
				>
					<div className="yes-no-label">{CONSTANTS.NO}</div>
					{checkMark(shouldBeActive("no"))}
				</Button>
				<Button
					id={replaceIdDelimiter(linkId) + "-yes"}
					data-testid={linkId + "-yes"}
					variant="outlined"
					className={"button " + (shouldBeActive("yes") ? "button-active" : "")}
					onClick={() => {
						setActive(true);
						props.onChangeHandler(linkId, text, type, true);
					}}
				>
					<div className="yes-no-label">{CONSTANTS.YES}</div>
					{checkMark(shouldBeActive("yes"))}
				</Button>
			</div>
		</div>
	);
};

export default YesNoInput;
