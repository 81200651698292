import { Box, Paper } from "@mui/material";
import { BOX_STYLING, PAPER_STYLING } from "../../utility/styles";
import LogoBanner from "../logo-banner/logo-banner";

import "./page-wrapper.css";

export type PageWrapperProps = {
	children: JSX.Element | JSX.Element[];
	enabled: boolean;
	id: string;
};

const PageWrapper = (props: PageWrapperProps) => {
	return (
		<>
			{props.enabled && <LogoBanner />}
			<div className="wrapper" id={props.id}>
				<Box sx={BOX_STYLING}>
					<Paper elevation={2} sx={PAPER_STYLING} className="paper paper-padding">
						{props.children}
					</Paper>
				</Box>
			</div>
		</>
	);
};

export default PageWrapper;
