enum FORM_TYPE {
	BOOLEAN = "boolean",
	CHOICE = "choice",
	DATE = "date",
	TEXT = "text",
	DISPLAY = "display",
	HEIGHT = "height"
}

type TFormAnswerOption = {
	valueString: string;
};

type TFormEnableCondition = {
	question: string;
	operator: string;
	answerBoolean?: boolean;
	answerDecimal?: number;
	answerInteger?: number;
	answerDate?: string;
	answerDateTime?: string;
	answerTime?: string;
	answerString?: string;
	answerCoding?: string;
	answerQuantity?: number;
	answerReference?: string;
};

type TFormElement = {
	linkId: string;
	prefix: string;
	text: string;
	type: string;
	enableWhen?: TFormEnableCondition[] | undefined;
	answerOption?: TFormAnswerOption[] | undefined;
	required?: boolean | undefined;
	repeats?: boolean | undefined;
};

enum FORM_RESPONSE_STATUS {
	IN_PROGRESS = "in-progress",
	COMPLETED = "completed",
	AMENDED = "amended",
	ENTERED_IN_ERROR = "entered-in-error",
	STOPPED = "stopped"
}

export default TFormElement;
export { FORM_TYPE, FORM_RESPONSE_STATUS };
export type { TFormAnswerOption, TFormEnableCondition };
