import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormValue } from "../../domain/FormValue";
import { useCallback, useEffect, useState } from "react";
import { FormItem } from "../../domain/FormItem";
import { QuestionnaireService } from "../../api/questionnaire-service";
import { CONSTANTS } from "../../utility/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import { questionnaireUrl } from "../../utility/questionnaireEndpoint";
import { constructQueryParams } from "../../utility/stringUtils";
import useHttp, { RequestConfig } from "../../hooks/use-http/use-http";
import { Address } from "../../domain/PatientAddress";

import PageWrapper from "../../components/page-wrapper/page-wrapper";
import FormGenerator, { FormGeneratorProps } from "../../components/form-components/form-generator/form-generator";

import "./FormViewerPage.css";

const FormViewerPage = () => {
	const navigate = useNavigate();
	const { consultId, zipCode } = useParams();
	const { state } = useLocation();
	const [queryParams] = useSearchParams();
	const [consent, setConsent] = useState<boolean | undefined>();
	const [formValues, setFormValues] = useState<FormValue[]>([]);
	const [formItems, setFormItems] = useState<FormItem[]>([]);
	const [patientAddress, setPatientAddress] = useState<Address>();
	const [procedure, setProcedure] = useState<string>("");
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [mode, setMode] = useState("");
	const { data, loading, sendRequest } = useHttp();

	const hasPharmacyPickupCustomQuestion = (currentState: any): boolean => {
		if (currentState.formValues && currentState.formValues.length > 0) {
			const savedValues = currentState.formValues as FormValue[];
			for (let formValue of savedValues) {
				if (
					formValue.text === CONSTANTS.PHARMACY_PREFERENCE_QUESTION &&
					formValue.value[0] === CONSTANTS.PHARMACY_PREFERENCE_DELIVERY
				) {
					return true;
				}
			}
		}
		return false;
	};

	const nextPage = (currentState: any): void => {
		const params = constructQueryParams([procedure, redirectUri, mode]);
		const hasCustomShipping = hasPharmacyPickupCustomQuestion(currentState);

		if (hasCustomShipping) {
			// Null out pharmacy if user wants their prescription delivered
			currentState.pharmacy = null;
			currentState.patientAddress = patientAddress;
			navigate(`/${zipCode}/consult/${consultId}/shipping${params}`, { state: currentState });
		} else {
			// Null out address if user wants to pick up their prescription
			currentState.address = null;
			navigate(`/${zipCode}/consult/${consultId}/pharmacy${params}`, { state: currentState });
		}
	};

	const prevPage = (currentState: any): void => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		currentState.patientAddress = patientAddress;
		navigate(`/${zipCode}/consult/${consultId}/start${queryParams}`, { state: currentState });
	};

	const parseQuestionnaireResponse = useCallback((response: any): void => {
		let formItems: FormItem[] = [];
		for (const index in response.Value.entry) {
			const entry = response.Value.entry[index];

			// Parse questionnaire questions
			if (entry.resource.resourceType === CONSTANTS.QUESTIONNAIRE) {
				const inputEntries = entry.resource.item;

				for (const entry of inputEntries) {
					const parsedEntry = FormItem.parseFormItem(entry);
					formItems.push(parsedEntry);
				}
				setFormItems(formItems);
			}

			// Parse out patient's address data
			if (entry.resource.resourceType === CONSTANTS.PATIENT) {
				if (entry.resource.address && entry.resource.address.length) {
					const address = entry.resource.address[0];
					setPatientAddress(new Address(address.line, address.city, address.state, address.postalCode));
				}
			}
		}
	}, []);

	useEffect(() => {
		const procedureParam = queryParams.get("procedure") as keyof typeof questionnaireUrl;
		const modeParam = queryParams.get("mode");
		const config = { url: QuestionnaireService.getQuestionnaireUrl(consultId, procedureParam) } as RequestConfig;
		sendRequest(config);

		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) {
			setRedirectUri(`redirect_uri=${redirectUri}`);
		}

		if (procedureParam) {
			setProcedure(`procedure=${procedureParam}`);
		}

		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [consultId, sendRequest, queryParams, parseQuestionnaireResponse]);

	useEffect(() => {
		if (state) {
			const { consent, formValues } = state as FormGeneratorProps;
			setConsent(consent);
			setFormValues(formValues);
		}

		if (data) {
			parseQuestionnaireResponse(data);
		}
	}, [state, data, parseQuestionnaireResponse]);

	const RenderFormViewer = () => {
		return (
			<>
				{loading ? (
					<Backdrop open={loading} sx={{ zIndex: 11 }}>
						<CircularProgress sx={{ color: "#FFF" }} data-testid="circular-progress" />
					</Backdrop>
				) : (
					<PageWrapper id="formViewer" enabled={true}>
						<FormGenerator
							formItems={formItems || []}
							consent={consent}
							consultId={consultId}
							formValues={formValues || []}
							nextPage={nextPage}
							prevPage={prevPage}
						></FormGenerator>
					</PageWrapper>
				)}
			</>
		);
	};

	return <RenderFormViewer />;
};

export default FormViewerPage;
